//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import XHeader from "@/components/Header";
import xDictionary from "@/assets/xDictionary.js";
export default {
  name: "dataDictionary",
  components: {
    XHeader,
  },
  data() {
    return {
      searchValue: "",
      tableData: [],
      editObj: {},
      edit: false,
      add: false,
      AddFather: false,
    };
  },
  watch: {
    searchValue() {
      if (this.searchValue == "") {
        this.getDic();
      }
    },
  },
  methods: {
    addMore() {
      //新增一个父级
      this.editObj = {};
      this.edit = true;
      this.add = true;
      this.AddFather = true;
    },
    handleEdit(index, row) {
      //编辑
      this.edit = true;
      this.editObj = row;
      if (!row.DictionaryID) {
        this.AddFather = true;
      } else {
        this.AddFather = false;
      }
    },
    handleDelete(index, row) {
      //删除
      this.$confirm("是否删除该字段?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {};
        if (row.DictionaryID) {
          param = {
            ID: row.DictionaryID,
            FID: row.FDictionaryID,
          };
        } else {
          param = {
            ID: row.FDictionaryID,
          };
        }
        this.$http
          .post("/Dictionary/DeleteDataDictionary.ashx", param)
          .then((res) => {
            if (res.res == 0) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getDic();
            }
          });
      });
    },
    addDicChild(index, row) {
      //添加子级
      this.edit = true;
      this.add = true;
      this.AddFather = false;
      this.editObj = {};
      let arr = row.children.map((item) => {
        return item.Value;
      });
      if (arr.length) {
        this.editObj.Value = String(Math.max(...arr) + 1);
      }
      this.editObj.FDictionaryID = row.FDictionaryID;
      this.editObj = Object.assign({}, this.editObj);
      console.log(this.editObj);
    },
    getDic() {
      //获取数据字典
      this.edit = false;
      this.add = false;
      this.AddFather = false;
      this.editObj = {};
      this.$http
        .post("/Dictionary/NewGetDataDictionaryList.ashx", {
          name: this.searchValue,
        })
        .then((res) => {
          if (res.res == 0) {
            this.tableData = res.data;
            this.$D = new xDictionary();
          }
        });
    },
    addDic() {
      //添加父级
      let icon = "";
      let Color = "";
      let FID = "";
      if (this.editObj.Icon) {
        icon = this.editObj.Icon;
      }
      if (this.editObj.Color) {
        Color = this.editObj.Color;
      }
      if (this.editObj.FDictionaryID) {
        FID = "";
      }
      this.$http
        .post("/Dictionary/AddDataDictionary.ashx", {
          key: this.editObj.Key,
          value: this.editObj.Value,
          FID,
          icon,
          Color,
        })
        .then((res) => {
          if (res.res == 0) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getDic();
            this.AddFather = false;
          }
        });
    },
    submit() {
      let obj = JSON.stringify(this.editObj);
      let isEdit = obj == "{}" ? true : false; //判断是添加还是编辑
      if (isEdit) {
        this.$message({
          type: "error",
          message: "输入必填字段及其枚举值",
        });
        return;
      }
      if (this.add && !this.editObj.FDictionaryID) {
        //添加父级
        this.addDic();
        return;
      }
      if (this.add && this.editObj.FDictionaryID) {
        if (!this.editObj.Key && !this.editObj.Value) {
          this.$message({
            type: "error",
            message: "输入必填字段及其枚举值",
          });
          return;
        }
        this.$http
          .post("/Dictionary/AddDataDictionary.ashx", {
            //添加子级
            key: this.editObj.Key,
            value: this.editObj.Value,
            FID: this.editObj.FDictionaryID,
            icon: this.editObj.Icon,
            Color: this.editObj.Color,
            custom: this.editObj.Custom,
            name: this.editObj.Name,
          })
          .then((res) => {
            if (res.res == 0) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getDic();
            }
          });

        return;
      }
      if (!this.add && !this.editObj.DictionaryID) {
        //修改父级
        this.$http
          .post("/Dictionary/EditDataDictionaryList.ashx", {
            dictionaryID: this.editObj.FDictionaryID,
            key: this.editObj.Key,
            value: this.editObj.Value,
            icon: this.editObj.Icon,
            Color: this.editObj.Color,
            custom: this.editObj.Custom,
          })
          .then((res) => {
            if (res.res == 0) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getDic();
              return;
            }
          });
      }
      if (!this.add && this.editObj.DictionaryID) {
        //修改子级
        this.$http
          .post("/Dictionary/EditDataDictionaryList.ashx", {
            dictionaryID: this.editObj.DictionaryID,
            fDictionaryID: this.editObj.FDictionaryID,
            key: this.editObj.Key,
            value: this.editObj.Value,
            icon: this.editObj.Icon,
            Color: this.editObj.Color,
            custom: this.editObj.Custom,
            name: this.editObj.Name,
          })
          .then((res) => {
            if (res.res == 0) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getDic();
              return;
            }
          });
      }
    },
    formStyle(row, index) {
      //父级样式
      if (!row.row.DictionaryID) {
        return {
          "font-weight": "bold",
        };
      }
    },
    cancel() {
      // this.AddFather = false
      this.add = false;
      this.edit = false;
    },
    search() {
      this.getDic();
    },
  },
  mounted() {
    this.getDic();
  },
};
